<template>
  <ion-page
    ><cyan-page-wrapper
      :title="esAsistencia ? 'Miembro del Cuerpo Electoral' : 'Contacto del Directorio'"
      :nav1="esAsistencia ? 'ASISTENCIA' : 'DIRECTORIO'"
      :nav2="datosCentro.nombre"
      :ambito="ambito"
      :backRoute="backRoute"
    >
      <div class="memberWrapperOuter">
        <div class="dummyDiv topDummyDiv"></div>
        <div class="memberWrapper">
          <div class="myRow myTitle">
            <div class="foto left">
              <img
                :src="memberData.image || 'assets/images/PersonaSinFoto.svg'"
              />
            </div>
            <div class="nombreycargo right">
              <div class="nombre">
                {{ memberData.nombre }}
              </div>
              <div class="cargo">
                {{ memberData.nombre_cargo }}
              </div>
            </div>
          </div>

          <div class="myRow" v-if="memberData.telefono">
            <div class="caption left">Teléfono de Contacto</div>
            <div class="value right">
              <a :href="telefonoUrl">{{ telefonoGuay }}</a>
            </div>
          </div>

          <div class="myRow" v-if="memberData.email">
            <div class="caption left">Correo</div>
            <div class="value right">
              <a :href="'mailto:' + memberData.email">{{ memberData.email }}</a>
            </div>
          </div>
          <div class="myRow" v-if="memberData.direccion">
            <div class="caption left">Dirección</div>
            <div class="preformatted value right">
              {{ memberData.direccion }}
            </div>
          </div>
        </div>

        <div class="asistencia" v-if="memberData.asistenciaMarcable && esAsistencia">
          <ion-button
            @click="marcarAsistencia(memberData.asistio)"
            expand="block"
            :color="memberData.asistio ? 'danger' : 'success'"
          >
            <!-- eslint-disable vue/no-deprecated-slot-attribute -->
            <ion-icon slot="start" :icon="memberData.asistio ? personRemove : personAdd"></ion-icon>

            {{
              memberData.asistio
                ? "ELIMINAR CONFIRMACIÓN DE ASISTENCIA"
                : "CONFIRMAR ASISTENCIA"
            }}
          </ion-button>
          <ion-button
            class="nuevoCargo"
            v-if="!memberData.asistio"
            @click="$router.replace('/jrslreplace/' + memberData.id)"
            expand="block"
            color="danger"
          >
            <!-- eslint-disable vue/no-deprecated-slot-attribute -->
            <ion-icon slot="start" :icon="swapHorizontal"></ion-icon>
              NO ASISTIÓ - SUSTITUIR MIEMBRO
          </ion-button>
        </div>
        <div class="dummyDiv bottomDummyDiv"></div>
      </div> </cyan-page-wrapper
  ></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store,
} from "@/components/CyanPageWrapper.vue";
import { IonButton, IonIcon } from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import router from "@/router";
import { personAdd, personRemove, swapHorizontal } from "ionicons/icons";
import { territorios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "DirectorioMemberStreamlined",
  components: {
    CyanPageWrapper,
    IonPage,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      personAdd,
      personRemove,
      swapHorizontal
    };
  },
  computed: {

    esAsistencia() {
      return (this as any).$route.path.split("/")[1] === "jrslasist";
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    idMember(): any {
      return (this as any).$route.params.id || 0;
    },
    memberData(): any {
      const s = store.getters.directorioStreamlined;
      if (!s || !s.value || !s.value.datosCargos || !s.value.datosCargos[(this as any).$route.params.id])
        return {};

      return s.value.datosCargos[(this as any).$route.params.id];
    },

    backRoute() {
      const pp = (this as any).$route.path.split("/");
      pp.pop();
      return pp.join("/");
    },
    telefonoGuay() {
      const t = this.memberData.telefono as string;
      if (!t) return "";
      if (!t.match(/^[0-9]{8}$/)) return t;
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    telefonoUrl() {
      const t = this.memberData.telefono as string;
      if (!t || t == "") return "";
      let url = "tel:";
      if (t.match("/^[0-9]{8}$/")) {
        url += t;
      } else {
        // partir en todo lo que no sean números, espacios, guiones, () y el + inicial

        const tieneMas = t[0] == "+";

        const tTrabajo = tieneMas ? t.substring(1) : t;

        const piezas = tTrabajo
          .replace(/[^-() 0-9]/g, "|")
          .split("|") as string[];

        if (tieneMas) url += "+";
        url += piezas[0].replace(/[^0-9]/g, "");
      }

      return url;
    },
  },
  methods: {
    marcarAsistencia(desmarcar: boolean) {
      const br = this.backRoute;
      cyanRequest(
        (desmarcar ? "jrsl/unsetPresencia/" : "jrsl/setPresencia/") +
          this.memberData.id,

        {
          needsAuth: true,
          isModal: true,
          isPost: true,
        }
      ).then(function (d) {
        if (d.ok) {
          store.commit("storeAsistenciaStreamlined", d);
          router.push(br);
        }
      });
    },
  },
});
</script>

<style scoped>
.memberWrapperOuter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: auto;
  bottom: 0;
  position: absolute;
  top: 0;
  left: 2vw;
  right: 2vw;
}
.memberWrapper {
  padding: 20px 0;
  margin: 20px;
  background: var(--ion-color-primary);
  border-radius: 20px;
  color: white;
  font-size: 120%;
}

.dummyDiv {
  flex: 0 0 10px;
}

.foto img {
  max-width: 120px;
  max-height: 120px;
  height: 120px;
  border-radius: 5px;
}

.nombre {
  font-weight: bold;
  font-size: 200%;
}

.memberWrapper a {
  color: white;
}

.caption {
  font-weight: bold;
}

.value {
  font-weight: 500;
}

.myRow {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 5px;
}

.myTitle {
  align-items: center;
}

.preformatted {
  white-space: pre-wrap;
}

.left,
.right {
  flex: 1 1 800px;
  text-align: center;
  padding: 5px;
}

.nuevoCargo {
  margin-top: 2em;
}

@media (min-width: 768px) and (min-height: 500px) {
  .bottomDummyDiv {
    flex: 0 0 30px;
  }
  ion-col {
    text-align: left;
  }
  .myRow {
    flex-wrap: nowrap;
    padding: 8px 5px;
  }
  .left {
    flex: 0 0 150px;
  }
  .right {
    flex: 1 1 120px;
  }
  .memberWrapper {
    padding: 20px;
  }
}
</style>