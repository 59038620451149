
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store,
} from "@/components/CyanPageWrapper.vue";
import { IonButton, IonIcon } from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import router from "@/router";
import { personAdd, personRemove, swapHorizontal } from "ionicons/icons";
import { territorios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "DirectorioMemberStreamlined",
  components: {
    CyanPageWrapper,
    IonPage,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      personAdd,
      personRemove,
      swapHorizontal
    };
  },
  computed: {

    esAsistencia() {
      return (this as any).$route.path.split("/")[1] === "jrslasist";
    },
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    idMember(): any {
      return (this as any).$route.params.id || 0;
    },
    memberData(): any {
      const s = store.getters.directorioStreamlined;
      if (!s || !s.value || !s.value.datosCargos || !s.value.datosCargos[(this as any).$route.params.id])
        return {};

      return s.value.datosCargos[(this as any).$route.params.id];
    },

    backRoute() {
      const pp = (this as any).$route.path.split("/");
      pp.pop();
      return pp.join("/");
    },
    telefonoGuay() {
      const t = this.memberData.telefono as string;
      if (!t) return "";
      if (!t.match(/^[0-9]{8}$/)) return t;
      return t.substr(0, 4) + "-" + t.substr(4);
    },
    telefonoUrl() {
      const t = this.memberData.telefono as string;
      if (!t || t == "") return "";
      let url = "tel:";
      if (t.match("/^[0-9]{8}$/")) {
        url += t;
      } else {
        // partir en todo lo que no sean números, espacios, guiones, () y el + inicial

        const tieneMas = t[0] == "+";

        const tTrabajo = tieneMas ? t.substring(1) : t;

        const piezas = tTrabajo
          .replace(/[^-() 0-9]/g, "|")
          .split("|") as string[];

        if (tieneMas) url += "+";
        url += piezas[0].replace(/[^0-9]/g, "");
      }

      return url;
    },
  },
  methods: {
    marcarAsistencia(desmarcar: boolean) {
      const br = this.backRoute;
      cyanRequest(
        (desmarcar ? "jrsl/unsetPresencia/" : "jrsl/setPresencia/") +
          this.memberData.id,

        {
          needsAuth: true,
          isModal: true,
          isPost: true,
        }
      ).then(function (d) {
        if (d.ok) {
          store.commit("storeAsistenciaStreamlined", d);
          router.push(br);
        }
      });
    },
  },
});
